
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import EntityInput from "@/components/EntityInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { useRoute, useRouter } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "employee-form",
  props: {
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  components: {
 },
  data() {
    return {
      errors: {},
      entityType: "roles",
      entityData: {
        name: "",
        permissions: [],
      },
      role: {},
    };
  },

  computed: {
    Title() {
      return this.mode == "new" ? "New Role" : "Role Edit";
    },
  },
  methods: {
    handleSave() {
      this.submitData().then(() => {
        ApiService.post(
          "users/" + this.$route.params.id.toString() + "/permissions",
          {
            affiliatePermissions: (this.user as any).affiliatePermissions,
            globalPermissions: (this.user as any).globalPermissions,
          }
        ).catch(this.catchErrors);
      });
    },

    handleDeletePermission(affiliateId) {
      ApiService.delete(
        "users/" + this.$route.params.id.toString() + "/permissions/affiliate/",
        affiliateId
      ).then(() => {
        this.retrieveData(this.$route.params.id.toString());
      });
    },
    catchErrors(error) {
      Swal.fire({
        text: error.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.errors = error.response.data.errors;
    },
    onSaveSuccess(response) {
      router.push({
        name: "role-list",
      });
    },
    retrieveData(id) {
      this.loading = true;
      ApiService.get(this.entityType, id)
        .then((result) => {
          this.entityData = result.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.catchErrors(err);
        });
    },
    submitData() {
      console.log(this.entityData);
      if (this.mode == "edit") {
        return ApiService.update(
          this.entityType,
          this.$route.params.id.toString(),
          this.entityData
        )
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      } else {
        return ApiService.post(this.entityType, this.entityData)
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    this.loading = true;

    ApiService.query("permissions", { perPage: 9999 })
      .then((result) => {
        this.loading = false;
        this.permissionList = result.data;
      })
      .catch((error) => {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Cerrar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      });

    if (this.$route.params.id) {
      this.retrieveData(this.$route.params.id.toString());
    }


  },
  created() {
    if (this.$route.params.id) {
      this.retrieveData(this.$route.params.id);
    }

    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        if (
          toParams.id !== "" &&
          toParams.id !== "new" &&
          toParams.id !== undefined
        ) {
          this.retrieveData(toParams.id);
        }
      }
    );
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Role Edit", ["Users"]);
    });

    let loading = ref(true);
    const permissionList = ref([]);
    let user = ref({});


    onMounted(() => {
      setCurrentPageTitle("Role Manager");
    });

    return {
      user,
      permissionList,
      loading,
    };
  },
});
